import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import motionlyServer from "./motionlyServer.html";
import Core from "~/services/Core";
import References from "~/util/References";
import { debounce } from "~/util/Utils";
import useStores from "~/hooks/useStores";
import unitService, { AD_UNIT_INTERSTITIAL } from "~/services/UnitService";
import experienceService from "~/services/ExperienceService";
import { useExperience } from "context";
import ImageContentTop from "./resources/content-top-mweb.jpg";
import ImageContentBottom from "./resources/content-bottom-mweb.jpg";
import ImageContent from "./resources/content-mweb.jpg";
import ImageNavigationBar from "./resources/navigation-bar-mweb.jpg";
import style from "./OguryPreview.module.scss";
import { buildAdFormatURL, buildOgyPlayerURL, buildSdkUrl } from "~/util/Constant";

const LOADMODE_SERVER = "server";
const LOADMODE_DYNAMIC = "dynamic";

const usePreviewWindow = ({ previewContainer }) => {
  const [previewWindow, setPreviewWindow] = useState();

  const onTagLoad = useCallback(() => {
    previewContainer.current?.contentWindow?.addEventListener("OnPreviewAdShow", () =>
      setPreviewWindow(previewContainer.current?.contentWindow?.frames[0])
    );
  }, []);

  useEffect(() => {
    if (!previewContainer.current) return;
    previewContainer.current.onload = onTagLoad;
  }, [previewContainer.current]);

  return previewWindow;
};

export default function OguryPreview({ loadMode = LOADMODE_SERVER, templateUrl, containerDimensions }) {
  const previewContainer = useRef(null);
  const { uiStore } = useStores();
  const { experience } = useExperience();
  const [inputsValues, setInputsValues] = useState();

  function getExportValues() {
    const rootInstance = Core.getDeepNodeById(References.ROOT_INPUT_ID, true);
    if (rootInstance) {
      (async function () {
        const { exportValues, idsWithBinaryValues } = await rootInstance.generateExportValues({
          assetsBaseUrl: "",
          resolveAssets: false,
          showErrors: false,
        });
        if (idsWithBinaryValues.length >= 1) {
          return;
        }
        setInputsValues(exportValues);
      })();
    }
  }

  const onUiStoreChanged = useCallback(debounce(getExportValues, 400), []);

  useEffect(() => {
    if (loadMode === LOADMODE_DYNAMIC) {
      onUiStoreChanged();
    }
  }, [uiStore]);

  const adUnitTechnicalId = useMemo(() => {
    return unitService.getCurrentUnitTechnicalId();
  }, [inputsValues]);

  const currentRatio = useMemo(() => {
    return experienceService.getCurrentRatio();
  }, [inputsValues]);

  const previewWindow = usePreviewWindow({
    previewContainer,
  });

  function computeUpdatePreviewParameters() {
    if (unitService.isBanner(adUnitTechnicalId)) {
      return {
        creative_source: "redirect",
        redirect_type: "ocs",
        creative_tag: motionlyServer,
      };
    }
    return { redirect_type: "ocs", mraid_tag: motionlyServer };
  }

  useEffect(() => {
    if (previewWindow) {
      if (loadMode === LOADMODE_DYNAMIC) {
        previewWindow.templateUrl = templateUrl;
      }
      if (loadMode === LOADMODE_SERVER) {
        previewWindow.experienceId = experience?.id;
        previewWindow.builderLanguage = window.builderLanguage;
        previewWindow.experienceRatio = experienceService.getCurrentRatio();
      }
      previewWindow.sdkUrl = buildSdkUrl("callbacks");
      previewWindow?.updatePreview(computeUpdatePreviewParameters());

      /* TODO keep this code for future use
           previewWindow.addEventListener(
        "message",
        function (event) {
          // Handle messages from iframes
        },
        false
      );*/
    }
  }, [previewWindow]);

  useEffect(() => {
    // Sending the information that the Motionly SDK should reload the creative, only if loadMode is "dynamic"
    if (previewWindow && inputsValues && loadMode === LOADMODE_DYNAMIC) {
      previewWindow.inputsValues = inputsValues;
      if (previewWindow.frames?.[0]) {
        previewWindow.frames[0].postMessage({ type: "message-from-iframe1", data: "setValues" }, "*");
      }
    }
  }, [inputsValues, previewWindow?.frames]);

  function specificParamsForInterstitialAndBanner() {
    if (adUnitTechnicalId === AD_UNIT_INTERSTITIAL || unitService.isBanner(adUnitTechnicalId)) {
      return `x_margin : 0,
              y_margin : 0`;
    }
    return "";
  }

  function computeIframeDimensions() {
    if (unitService.isBanner(adUnitTechnicalId)) {
      const configuration = unitService.getOguryPlayerConfiguration({
        creativeRatio: currentRatio,
        containerWidth: previewContainer?.current?.clientWidth,
        containerHeight: previewContainer?.current?.clientHeight,
      })[adUnitTechnicalId];

      const adjustedConfiguration = adjustBannerSize(configuration);
      return {
        width: adjustedConfiguration.width,
        height: adjustedConfiguration.height,
      };
    }
  }

  // Adjusts the banner size to fit the ad container (scaling to display the whole)
  function adjustBannerSize(configuration) {
    if (unitService.isBanner(adUnitTechnicalId)) {
      let height = configuration.height;
      let width = configuration.width;
      if (width >= containerDimensions.width) {
        width = containerDimensions.width;
        height = (width * configuration.height) / configuration.width;
      }
      if (height >= containerDimensions.height) {
        height = containerDimensions.height;
        width = (height * configuration.width) / configuration.height;
      }
      return { ...configuration, width, height };
    }
    return configuration;
  }

  function computeAdFormatURL() {
    const adFormat = unitService.getUnits().find(unit => unit.technicalId === adUnitTechnicalId)?.adFormat;
    return buildAdFormatURL(adFormat);
  }

  function getConfigurationPerAdUnit() {
    const configuration = unitService.getOguryPlayerConfiguration({
      creativeRatio: currentRatio,
      containerWidth: previewContainer?.current?.clientWidth,
      containerHeight: previewContainer?.current?.clientHeight,
    })[adUnitTechnicalId];
    const adjustedConfiguration = adjustBannerSize(configuration);
    return Object.keys(adjustedConfiguration)
      ?.map(key => {
        const value = JSON.stringify(adjustedConfiguration[key]);
        return `"${key}": ${value}`;
      })
      .join(", ");
  }

  function renderHeaderAndBackgrounds() {
    const commonImageStyle = `style="width: 100%; vertical-align: middle;"`;
    if (unitService.isBanner(adUnitTechnicalId)) {
      return `<header id="page-header"></header>`;
    }
    if (unitService.isInArticle(adUnitTechnicalId)) {
      return ` 
        <img 
          src="${ImageContentTop}"
          ${commonImageStyle}
          alt="Article part. 1"
        />
        <div id="in-article-slot-selector"></div>
        <img 
          src="${ImageContentBottom}"
         ${commonImageStyle}
          alt="Article part. 2"
        />
      `;
    }
    return `
      <img 
        src="${ImageNavigationBar}"
       ${commonImageStyle}
        alt="Navigation bar"
      />
      <header id="page-header"></header>
      <div id="content-image-wrapper" style="position: ${unitService.isThumbnail(adUnitTechnicalId) ? "fixed" : ""};">
        <img 
          id="content-image"
          src="${ImageContent}"
         ${commonImageStyle}
          alt="Content"
        />
      </div>
    `;
  }

  function generateIframeContent() {
    return `
      <html>
        <style>
           html, body {
              padding: 0; margin: 0;
            }
            body {
              -ms - overflow - style: none;
              overflow-y: scroll;
            }
            body::-webkit-scrollbar {
              display: none;
            }
        </style>
        <body>
          ${renderHeaderAndBackgrounds()}            
          <div>
            <script id="og-configure-player">
                      
              window.isOgyDemoPage = true;
              window.open = () => {};
              window.OG_CONTEXT = {
                og_tracking_disabled: true
              };
              window.OG_SHOWCASE = {
                CONFIG: {
                  bid_response: {
                    ${getConfigurationPerAdUnit()},
                    ad_content: ${computeAdFormatURL()},
                    media_type: "image",
                  },
                  ad_unit_config: {
                    asset_key: "OGY-C3FE4ADC0126",
                    ad_unit_id: "0251ec40-fc3b-0139-afe5-0242ac120004",
                    callbacks: {
                      on_ad_show: (adUnit, adFormatState) => {
                        window.dispatchEvent(new CustomEvent('OnPreviewAdShow'));
                        //WORKAROUND : This is an ugly workaround to fix the issue with the thumbnail ad-unit, where the creative is not rendering if there is a background image taller than the iframe container
                        setTimeout(() =>  document.getElementById("content-image-wrapper").style.position = "", 1000);
                      },
                      on_ad_complete: (adUnit) => window.dispatchEvent(new CustomEvent('OnPreviewAdComplete')),
                      on_position_change: (adUnit, adFormatState) => console.log("on_position_change", adUnit, adFormatState),
                    },
                    params: {
                      header_selector: "#page-header",
                      ad_slot_selector: "#in-article-slot-selector",
                      ${specificParamsForInterstitialAndBanner()}
                    }
                  }
                }
              };
            </script>
          </div>
         <script type="text/javascript" src="${buildOgyPlayerURL()}"></script>
        </body>
      </html>
    `;
  }

  function getBestFit() {
    const portraitRatio = 324 / 541; // Aspect ratio: width / height for portrait
    const landscapeRatio = 541 / 324; // Aspect ratio: width / height for landscape

    const targetRatio = window.previewOrientation === "landscape" ? landscapeRatio : portraitRatio;

    let computedHeight = containerDimensions.height;
    let computedWidth = computedHeight * targetRatio;

    if (computedWidth > containerDimensions.width) {
      computedWidth = containerDimensions.width;
      computedHeight = computedWidth / targetRatio;
    }

    return {
      width: computedWidth,
      height: computedHeight,
    };
  }

  return (
    <div style={getBestFit()} className={!unitService.isBanner(adUnitTechnicalId) ? style.deviceContainer : ""}>
      <iframe
        style={computeIframeDimensions()}
        className={style.iframe}
        ref={previewContainer}
        srcDoc={generateIframeContent()}
      />
    </div>
  );
}
