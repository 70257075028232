import unitService from "~/services/UnitService";

async function afterFetchTemplate(template) {
  const unitSyntheticInputDefinition = await unitService.getUnitsTemplateDefinition(template.id);
  template.inputs.unshift(unitSyntheticInputDefinition);
  return template;
}

export default {
  afterFetchTemplate,
};
