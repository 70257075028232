import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "./Preview.module.scss";
import ResizeObserver from "resize-observer-polyfill";
import { useTranslation } from "react-i18next";
import { Alert, Button, Radio, Space } from "@ogury/design-system";
import { debounce } from "~/util/Utils";
import { LocalPreview, OguryPreview, PreviewOptions } from "./components";
import useStores from "~/hooks/useStores";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";

const RELOAD_DEBOUNCE_MILLISECONDS = 500;

const MODES = {
  LOCAL: "Live",
  REMOTE: "Demo",
};

export default function Preview({ templateUrl, afterSaveSeed, onReloadAndSave }) {
  const [display, setDisplay] = useState(true);
  const [containerDimensions, setContainerDimensions] = useState({
    width: 300,
    height: undefined,
  });
  const [t] = useTranslation();
  const [mode, setMode] = useState(MODES.LOCAL);
  const [saving, setSaving] = useState(false);
  const { uiStore } = useStores();
  const initialized = useRef(null);
  const [warningStoreChanged, setWarningStoreChanged] = useState(false);

  function handleOnRefreshComponent() {
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
    }, RELOAD_DEBOUNCE_MILLISECONDS);
  }

  async function handleOnChangeMode(newMode) {
    if (mode === MODES.LOCAL && newMode === MODES.REMOTE && warningStoreChanged) {
      setSaving(true);
      onReloadAndSave();
    }
    setMode(newMode);
  }

  useEffect(() => {
    setSaving(false);
  }, [afterSaveSeed]);

  const cbRef = useRef();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.contentRect.height >= 400) {
          // Arbitrary value to stop reloading the preview if the container height is too small. This causes a kind of infinite loop
          setContainerDimensions({
            width: Math.round(entry.contentRect.width) - 20,
            height: Math.round(entry.contentRect.height) - 20,
          });
          reload(); // need to reload everytime even for height change
        }
      }
    });

    resizeObserver.observe(cbRef.current);
  }, []);

  const reload = useCallback(debounce(handleOnRefreshComponent, RELOAD_DEBOUNCE_MILLISECONDS), []);

  useEffect(() => {
    const currentUiStore = JSON.stringify(uiStore);
    if (!initialized.current) {
      initialized.current = currentUiStore;
      return;
    }
    setWarningStoreChanged(initialized.current !== currentUiStore);
  }, [uiStore, initialized]);

  useEffect(() => {
    if (afterSaveSeed) {
      setWarningStoreChanged(false);
      initialized.current = JSON.stringify(uiStore);
      reload();
    }
  }, [afterSaveSeed]);

  function renderWarningStoreChanged() {
    return (
      <div className={style.overlayContainer}>
        <Space direction="vertical" align="center">
          <Alert
            type="warning"
            closable={false}
            showIcon
            message={t("rightPanel.errors.remotePreview.warningStoreChangedTitle")}
            description={t("rightPanel.errors.remotePreview.warningStoreChanged")}
          />
          <Button type="secondary" onClick={onReloadAndSave}>
            {t("header.button.saveAndReload")}
          </Button>
        </Space>
      </div>
    );
  }

  function renderPreview() {
    if (mode === MODES.LOCAL) {
      return <LocalPreview templateUrl={templateUrl} containerWidth={containerDimensions.width} />;
    }
    return (
      <div className={style.oguryPreviewContainer}>
        {warningStoreChanged && !saving && renderWarningStoreChanged()}
        <OguryPreview
          containerDimensions={containerDimensions}
          seed={afterSaveSeed}
          onReloadAndSave={onReloadAndSave}
          templateUrl={templateUrl}
        />
      </div>
    );
  }

  return (
    <div className={style.iframeAndLanguageContainer} data-testid="preview-container">
      <div className={style.languageContainer} data-testid="language-and-reload-container">
        <Radio.Group value={mode} onChange={handleOnChangeMode}>
          <Radio.Button value={MODES.LOCAL}>{MODES.LOCAL}</Radio.Button>
          <Radio.Button value={MODES.REMOTE}>{MODES.REMOTE}</Radio.Button>
        </Radio.Group>
        <PreviewOptions isLocal={mode === MODES.LOCAL} onChange={handleOnRefreshComponent} />
        <Button
          type="secondary"
          icon={<RefreshLineIcon />}
          iconPosition={"iconOnly"}
          onClick={handleOnRefreshComponent}
        />
      </div>
      <div ref={cbRef} className={style.renderContainer}>
        {display && renderPreview()}
      </div>
    </div>
  );
}
