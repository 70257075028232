const units = [
  {
    technicalId: "overlay_thumbnail",
    name: "Thumbnail",
    properties: [
      {
        ratio: 1.3333,
        containerPolicy: "fitHeight",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.8,
      maximumMultiplier: 1.2,
      precisionFactor: 10,
    },
    adFormat: {
      id: 201,
      adUnit: "overlay_thumbnail",
    },
  },
  {
    technicalId: "interstitial",
    name: "Interstitial",
    properties: [
      {
        ratio: 0.6,
        containerPolicy: "fit",
        isDefault: false,
      },
      {
        ratio: 0.8,
        containerPolicy: "fitHeight",
        dangerZonesSizePercentage: 21.9,
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.6,
      maximumMultiplier: 1.4,
      precisionFactor: 10,
    },
    adFormat: {
      id: 199,
      adUnit: "interstitial",
    },
  },
  {
    technicalId: "in_article-reveal",
    name: "In Article Reveal",
    group: "in_article",
    properties: [
      {
        ratio: 0.6,
        containerPolicy: "fit",
        isDefault: false,
      },
      {
        ratio: 0.8,
        containerPolicy: "fitHeight",
        dangerZonesSizePercentage: 21.9,
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.6,
      maximumMultiplier: 1.4,
      precisionFactor: 10,
    },
    adFormat: {
      id: 212,
      adUnit: "in_article",
    },
  },
  {
    technicalId: "in_article-300x250",
    name: "In Article MPU",
    group: "in_article",
    properties: [
      {
        ratio: 1.3333,
        containerPolicy: "fitHeight",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.8,
      maximumMultiplier: 1.2,
      precisionFactor: 10,
    },
    adFormat: {
      id: 210,
      adUnit: "in_article",
    },
  },
  {
    technicalId: "in_article-landscape",
    name: "In Article Landscape",
    group: "in_article",
    properties: [
      {
        ratio: 1.7777,
        containerPolicy: "fit",
        isDefault: false,
      },
      {
        ratio: 4.4,
        containerPolicy: "fitHeight",
        dangerZonesSizePercentage: 29.9,
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.8,
      maximumMultiplier: 2,
      precisionFactor: 10,
    },
    adFormat: {
      id: 211,
      adUnit: "in_article",
    },
  },
  {
    technicalId: "header_ad",
    name: "Header",
    properties: [
      {
        ratio: 1.7777,
        containerPolicy: "fit",
        isDefault: false,
      },
      {
        ratio: 4.4,
        containerPolicy: "fitHeight",
        dangerZonesSizePercentage: 29.9,
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.8,
      maximumMultiplier: 2,
      precisionFactor: 10,
    },
    adFormat: {
      id: 202,
      adUnit: "header_ad",
    },
  },
  {
    technicalId: "footer_ad",
    name: "Footer",
    properties: [
      {
        ratio: 3.2,
        containerPolicy: "fit",
        isDefault: false,
      },
      {
        ratio: 1.7777,
        containerPolicy: "fit",
        isDefault: false,
      },
      {
        ratio: 4.4,
        containerPolicy: "fitHeight",
        dangerZonesSizePercentage: 13.64,
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.8,
      maximumMultiplier: 2,
      precisionFactor: 10,
    },
    adFormat: {
      id: 203,
      adUnit: "footer_ad",
    },
  },
  {
    technicalId: "banner-970x90",
    name: "Banner 970x90",
    group: "banner",
    properties: [
      {
        ratio: 10.7777,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-970x250",
    name: "Banner 970x250",
    group: "banner",
    properties: [
      {
        ratio: 3.88,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-728x90",
    name: "Banner 728x90",
    group: "banner",
    properties: [
      {
        ratio: 8.0888,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-640x360",
    name: "Banner 640x360",
    group: "banner",
    properties: [
      {
        ratio: 1.7777,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-336x280",
    name: "Banner 336x280",
    group: "banner",
    properties: [
      {
        ratio: 1.2,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-320x50",
    name: "Banner 320x50",
    group: "banner",
    properties: [
      {
        ratio: 6.4,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-320x480",
    name: "Banner 320x480",
    group: "banner",
    properties: [
      {
        ratio: 0.6666,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-320x100",
    name: "Banner 320x100",
    group: "banner",
    properties: [
      {
        ratio: 3.2,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-300x600",
    name: "Banner 300x600",
    group: "banner",
    properties: [
      {
        ratio: 0.5,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-300x50",
    name: "Banner 300x50",
    group: "banner",
    properties: [
      {
        ratio: 6,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-300x250",
    name: "Banner 300x250",
    group: "banner",
    properties: [
      {
        ratio: 1.2,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
  {
    technicalId: "banner-160x600",
    name: "Banner 160x600",
    group: "banner",
    properties: [
      {
        ratio: 0.2666,
        containerPolicy: "fit",
        isDefault: true,
      },
    ],
    matchingRuleRatio: {
      minimumMultiplier: 0.9,
      maximumMultiplier: 1.1,
      precisionFactor: 10,
    },
    adFormat: {
      id: 206,
      adUnit: "standard_banners",
    },
  },
];

export default units;
